import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  projectId: process.env.REACT_APP_PROJECTID,
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID
};

// Start Firebase Initialize
const app = initializeApp(firebaseConfig);

export default app;
