import "./Alert.css";

function Alert({ message, type, onClose }) {
  return (
    <div className={`alert ${type}`}>
      {message}
      <button onClick={onClose}> &nbsp; X &nbsp; </button>
    </div>
  );
}

export default Alert;
