import React from "react";
import { Link } from "react-router-dom";
import "./PageNotFound.css";
import Navbar from "../Component/HomePage/Navbar/Navbar";
import Footer from "../Component/HomePage/Footer";

const PageNotFound = ({ scrollToSection }) => {
  return (
    <>
      <Navbar scrollToSection={scrollToSection} />
      <div className="page-not-found">
        <div className="fix-container">
          <h1> 404 - Page Not Found </h1>
          <p>
            The page you are looking for doesn’t exist or there was an error in
            the link you followed
          </p>
          <div className="home-btn-sec">
            <Link to="/">Go To Homepage</Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
