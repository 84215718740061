import React from "react";

const appData = {
  heading: "Get app now",
  text: "The ABillion Trade online trading platform provides a wide range of useful features that you can try out and utilize..",
  links: [
    {
      href: "http://apps.apple.com/us/app/abillion-trade-analytics/id6464286930",
      imgSrc: "../assets/images/ios.svg",
      alt: "iOS appstore link",
      width: "175",
      height: "55",
    },
    {
      href: "http://play.google.com/store/apps/details?id=com.app.abillion",
      imgSrc: "../assets/images/play.svg",
      alt: "Android playstore link",
      width: "175",
      height: "55",
    },
  ],
  imageSrc: "../assets/images/contact-page.png",
  imgAlt: "device image",
};

function ContactDevice() {
  return (
    <div className="contact-getapp">
      <div className="contact-content">
        <div className="contact-heading">
          <h2>{appData.heading}</h2>
        </div>
        <div className="contact-text">
          <p>{appData.text}</p>
        </div>
        <div className="contact-link">
          {appData.links.map((link, index) => (
            <a key={index} href={link.href} target="_blank" rel="noreferrer">
              <img
                src={link.imgSrc}
                alt={link.alt}
                width={link.width}
                height={link.height}
              />
            </a>
          ))}
        </div>
      </div>
      <div className="contact-image">
        <img
          src={appData.imageSrc}
          alt={appData.imgAlt}
          width={396}
          height={375}
        />
      </div>
    </div>
  );
}

export default ContactDevice;
