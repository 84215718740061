import React, { useState, useEffect } from "react";
import ContactDevice from "../Component/ContactPage/ContactDevice";
import { getDatabase, ref, push } from "firebase/database";
import app from "../config/firebase";
import Alert from "../utils/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useForm } from "react-hook-form";
import "./Contact.css";
import useScrollToTop from "../Component/usScrollToTop";
import Navbar from "../Component/HomePage/Navbar/Navbar";
import Footer from "../Component/HomePage/Footer";
const contactUsData = [
  {
    inputfield: "full_name",
    label: "Name*",
    type: "text",
    placeholder: "Name",
  },
  {
    inputfield: "email",
    label: "Email*",
    type: "email",
    placeholder: "Email Address",
  },
  {
    inputfield: "phone_number",
    label: "Mobile*",
    type: "tel",
    placeholder: "+91 0000 0000 00",
  },
  {
    inputfield: "message",
    label: "Message*",
    type: "textarea",
    placeholder: "Enter Your Message",
  },
];
const Contact = ({ scrollToSection }) => {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const {
    handleSubmit,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm();

  const watchFields = watch(["full_name", "phone_number", "email", "message"]);

  useScrollToTop();

  useEffect(() => {
    const isPhoneNumberValid =
      phoneNumber.trim() !== "" && validatePhoneNumber(phoneNumber);
    const areAllFieldsFilled = watchFields.every((field) => field !== "");
    setIsFormEnabled(isPhoneNumberValid && areAllFieldsFilled);
  }, [watchFields, phoneNumber]);

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{6,16}$/;
    return phoneRegex.test(phoneNumber);
  };

  const onSubmit = (data) => {
    if (!captchaVerified) {
      setShowRecaptcha(true);
      return;
    }
    data.mobile_number = "+" + phoneNumber;

    // Firebase submission logic
    const db = getDatabase(app);
    const contactRef = ref(db, "webContacts");
    push(contactRef, data)
      .then(() => {
        reset();
        setPhoneNumber("91");
        grecaptcha.reset();
        setAlert({
          show: true,
          message: "Message sent successfully!",
          type: "success-notify",
        });
        setTimeout(() => setAlert({ ...alert, show: false }), 5000);
        setCaptchaVerified(false);
        setShowRecaptcha(false);
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: "Failed to send message. Please try again.",
          type: "error-notify",
        });
        setTimeout(() => setAlert({ ...alert, show: false }), 5000);
      });
  };

  const handleRecaptcha = () => {
    setCaptchaVerified(true);
  };

  const handleCloseAlert = () => {
    setAlert({ show: false, message: "", type: "" });
  };

  return (
    <>
      <Navbar scrollToSection={scrollToSection} />
      <div className="contact-container">
        <div className="fix-container">
          <div className="contactus-main">
            <h2>Get In Touch</h2>
          </div>
          <div className="contact-us-scn">
            <div className="contact-us-form">
              <div className="input-field">
                <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                  {contactUsData.map((inputData) => (
                    <div key={inputData.inputfield}>
                      <label htmlFor={inputData.inputfield}>
                        {inputData.label}
                      </label>
                      <br />
                      {inputData.type === "textarea" ? (
                        <textarea
                          id={inputData.inputfield}
                          rows={4}
                          cols={15}
                          className="inputfield"
                          placeholder={inputData.placeholder}
                          {...register(inputData.inputfield)}
                          style={{ resize: "none" }}
                        />
                      ) : inputData.type === "tel" ? (
                        <PhoneInput
                          country={"in"}
                          placeholder="00000-00000"
                          value={phoneNumber}
                          onChange={(value) => setPhoneNumber(value)}
                          className="phone-number"
                          inputProps={{
                            name: "phone_number",
                            required: true,
                          }}
                          countryCodeEditable={false}
                        />
                      ) : (
                        <input
                          type={inputData.type}
                          id={inputData.inputfield}
                          className="inputfield"
                          placeholder={inputData.placeholder}
                          required
                          {...register(inputData.inputfield)}
                        />
                      )}
                      <p className="error">
                        {errors[inputData.inputfield] && (
                          <span>{errors[inputData.inputfield].message}</span>
                        )}
                      </p>
                    </div>
                  ))}
                  <div className="recaptcha-container">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={handleRecaptcha}
                    />
                  </div>

                  <input
                    type="submit"
                    id="submitBtn"
                    value="Submit"
                    className="submitbtn"
                    disabled={!isFormEnabled || showRecaptcha}
                    style={{
                      backgroundColor: isFormEnabled
                        ? "#20AC91"
                        : "rgb(136 198 186)",
                      color: "white",
                      cursor: isFormEnabled ? "pointer" : "auto",
                    }}
                  />
                </form>
              </div>
              {alert.show && (
                <Alert
                  message={alert.message}
                  type={alert.type}
                  onClose={handleCloseAlert}
                />
              )}
            </div>
            <ContactDevice />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Contact;
