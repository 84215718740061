/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./Banner.css";
const bannerData = {
  bannerText: {
    mainText:
      "<b>Tracking</b> Exclusively <b>High Probability </b> Stocks, everyday",
    innerText:
      "Level up your investments with the power of Analytics & Technology.",
  },

  bannerImage: "../assets/images/banner.jpg",

  altText: "play button ",
  videoUrl: "../assets/video/Dashboard_Video.mp4",
  mobileFrame: "../assets/images/mobile_frm.svg",
  devicePoster: "../assets/images/deviceposter.png",
};

export const Banner = () => {
  const {
    bannerText,
    videoUrl,
    bannerImage,
    altText,
    mobileFrame,
    devicePoster,
  } = bannerData;

  return (
    <div className="banner-container" id="home">
      <div className="fix-container">
        <div className="device">
          <div
            className="banner-scn"
            style={{ backgroundImage: `url(${bannerImage})` }}
          >
            <div className="bannertext-scn">
              <h1
                dangerouslySetInnerHTML={{ __html: bannerText.mainText }}
                className="bannertext"
              ></h1>
              <p>{bannerText.innerText}</p>
            </div>

            <div className="device-screen">
              <img src={mobileFrame} alt={altText} width={390} height={800} />
              <div className="banner-video">
                <video
                  autoPlay="autoplay"
                  muted
                  loop={true}
                  preload="auto"
                  playsInline
                  poster={devicePoster}
                  className="video"
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
