import React, { useEffect } from "react";
import Home from "./Layout/Home";
import "./App.css";
import { Route, Routes } from "react-router-dom"; // Import Routes from react-router-dom

import Contact from "./Layout/Contact";
import Footer from "./Component/HomePage/Footer";
import Navbar from "./Component/HomePage/Navbar/Navbar";

import CookieConsent from "react-cookie-consent";
import PageNotFound from "./Layout/PageNotFound";

function App() {
  const scrollToSection = (sectionId) => {
    const targetElement = document.getElementById(sectionId);
    let offset = 100;
    if (targetElement) {
      let targetPosition = targetElement.offsetTop - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {/* <Navbar scrollToSection={scrollToSection} /> */}
      <Routes>
        <Route
          exact
          path="/"
          element={<Home scrollToSection={scrollToSection} />}
        />
        <Route
          path="/contact"
          element={<Contact scrollToSection={scrollToSection} />}
        />
        <Route
          path="*"
          element={<PageNotFound scrollToSection={scrollToSection} />}
        />
      </Routes>

      <CookieConsent
        flipButtons
        enableDeclineButton
        location="bottom"
        buttonText="Accept"
        cookieName="AbillionWebCookies"
        style={{ background: "black" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This site uses cookies to personalize content, streamline your visit,
        and enhance functionality by remembering your preferences, ensuring a
        more efficient and tailored browsing experience.
      </CookieConsent>
    </>
  );
}

export default App;
