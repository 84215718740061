import React from "react";
import "./DownloadApp.css";

const appData = {
  apps: [
    {
      bgColor:
        "linear-gradient(127.14deg, rgba(255, 255, 255, 0.15) -9.9%, rgba(255, 255, 255, 0) 61.46%) ",
      platform: "App Store",
      icon: "../assets/images/app-icon.svg",
      name: "App Store",
      // downloads: "44M +",
      ratings: "5.0",
      scannerImage: "../assets/images/appstore-scanner.png",
      appImage: "../assets/images/ios-image.png",
      appRating: "../assets/images/rating-icon.svg",
      link: "http://apps.apple.com/us/app/abillion-trade-analytics/id6464286930",
    },
    {
      bgColor: "#2f2d30",
      platform: "Android",
      icon: "../assets/images/android-icon.svg",
      name: "Play Store",
      // downloads: "44M +",
      ratings: "5.0",
      scannerImage: "../assets/images/android-scanner.png",
      appImage: "../assets/images/android-image.png",
      appRating: "../assets/images/rating-icon.svg",
      link: "http://play.google.com/store/apps/details?id=com.app.abillion",
    },
  ],
};

function DownloadApp() {
  return (
    <div className="download-app-scn">
      <div className="fix-container">
        <div className="downlaod-app-content">
          <div className="downloat-app-heading">
            <h4>Download App Now</h4>
          </div>
          <div className="downloat-app-text">
            <p>
              The ABillion Trade online trading platform provides a wide range
              of useful features that you can try out and utilize.
            </p>
          </div>
        </div>
        <div className="download-app-image">
          {appData.apps.map((app, index) => (
            <a href={app.link} key={index} target="_blank" rel="noreferrer">
              <div
                className="download-android"
                style={{ background: app.bgColor }}
              >
                <div className="download-content">
                  <div className="download-heading">
                    <span className="android-icon">
                      <img src={app.icon} alt="" width={24} height={21} />
                    </span>
                    <h4>{app.name}</h4>
                  </div>
                  <div className="android-rating">
                    <h4>{app.ratings}</h4>
                    <span className="ratings-icon">
                      <img src={app.appRating} alt="" width={10} height={10} />
                    </span>
                  </div>
                  <p className="app-rating">App Ratings</p>
                  <div className="android-scanner">
                    <img
                      src={app.scannerImage}
                      alt=""
                      width={127}
                      height={149}
                    />
                  </div>
                </div>
                <div className="android-image">
                  <img
                    src={app.appImage}
                    alt="android"
                    width={371}
                    height={525}
                  />
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
