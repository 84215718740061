import React from "react";
import { Link } from "react-router-dom";

const Mobilemenu = ({ navLinks, handleMenuLinkClick, closeMenu, show }) => {
  const handleClick = (linkId) => {
    handleMenuLinkClick(linkId);
    closeMenu();
  };
  return (
    <div className="mobile-menu">
      <ul className={show ? "nav-menu active" : "nav-menu "}>
        {navLinks.map((link) => (
          <li key={link.id} className="navbar-link">
            <Link
              className="link"
              to={link.path}
              duration={500}
              onClick={() => handleClick(link.id)}
            >
              {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Mobilemenu;
