/* eslint-disable no-undef */
import { Banner } from "../Component/HomePage/Banner";
import Investment from "../Component/HomePage/Investment";
import PortfolioComponent from "../Component/HomePage/Portfolio";

import Testimonial from "../Component/HomePage/Testimonial";
import DownloadApp from "../Component/HomePage/DownloadApp";
import Feature from "../Component/HomePage/Feature";
import useScrollToTop from "../Component/usScrollToTop";
import Navbar from "../Component/HomePage/Navbar/Navbar";
import Footer from "../Component/HomePage/Footer";

const investmentDataList = [
  {
    title: "Evolve your craftsmanship with investments",
    barGraphIcon: "../assets/images/bar-graph-icon.svg",
    sections: [
      {
        name: "stock",
        content:
          "Get exclusively high probability stocks by simply tapping on High probability stocks.",
        image: "../assets/images/trake-ongoing-image.png",
        altText: "trake-ongoing-image",
      },
      {
        name: "performance",
        content:
          "View Over all performance of total HPS (High probability stocks) generated over each trading day",
        image: "../assets/images/trake-ongoing-past-performance.png",
        altText: "trake-ongoing-past-performance",
      },
      {
        name: "insight",
        content: "Get Insights on Current Trending sectors",
        image: "../assets/images/tranding-sector.png",
        altText: "tranding-sector",
      },
    ],
  },
  {
    bgColor: "red",
    title: "Track Ongoing and Past performance",
    barGraphIcon: "../assets/images/bar-graph-icon.svg",

    sections: [
      {
        name: "trake",
        content:
          "Track performance of all posted stocks by tapping on 'Day' time frames",
        image: "../assets/images/investment-duration.png",
        altText: "trake-ongoing1",
      },
      {
        name: "target",
        content:
          "We have minimum of Target to 10% with Time Horizon of 1 to 4 weeks, you can check out all open, Target/Stop loss hits stocks by using filter",
        image: "../assets/images/investment-duration-option.png",
        altText: "trake-ongoing2",
      },
      {
        name: "invest",
        content:
          " Tap on Today to get exclusive High Probability Stocks to Invest today for next 1 to 4 weeks",
        image: "../assets/images/investmet-day-by-day.png",
        altText: "trake-ongoing3",
      },
      {
        name: "basket",
        content:
          " Tap on tomorrow to get basket of high probability stocks which seems to perform well tomorrow or in next few days, also technical users can leverage this more to do individual analysis before investing, This gets updated at every trading day at 7:30 PM",
        image: "../assets/images/investment-tommorow.png",
        altText: "trake-ongoing4",
      },
    ],
  },
  {
    title: "Envision market movements",
    barGraphIcon: "../assets/images/bar-graph-icon.svg",
    sections: [
      {
        name: "buying",
        content:
          "Check out buying and selling activities by FII/DII, Retailers and Prop traders",
        image: "../assets/images/performance.png",
        altText: "trake-ongoing-image",
      },
      {
        name: "selling",
        content:
          "Keep track on Buying and Selling threshold derived by historical trading activities",
        image: "../assets/images/performnce-threshold.png",
        altText: "trake-ongoing-past-performance",
      },
      {
        name: "profit",
        content: "Check out Profit returns for historical HPS",
        image: "../assets/images/performance-profit.png",
        altText: "tranding-sector",
      },
    ],
  },
];

const Home = ({ scrollToSection }) => {
  useScrollToTop();
  return (
    <>
      <Navbar scrollToSection={scrollToSection} />
      <Banner />
      {investmentDataList.map((data, index) => (
        <Investment key={index} data={data} />
      ))}
      <PortfolioComponent />
      <Feature />
      <Testimonial
        testimonialHeading="Our Testimonials"
        testimonialHeadingText="The ABillion Trade online trading platform provides a wide range of useful features that you can try out and utilize."
      />
      <DownloadApp />
      <Footer />
    </>
  );
};

export default Home;
