import React from "react";
import "./Portfolio.css";

const data = [
  {
    bgColor: "#ffb581",
    image: {
      src: "../assets/images/portfolio-image.png",
      alt: "portfolio-image",
    },
    barGraph: {
      src: "../assets/images/portfolio-bar-graph.png",
      alt: "portfolio-bar-graph",
    },
    heading: "Portfolio",
    text1: "Add stocks to your portfolio to keep track of movements.",
    text2: " Get Important alerts over stocks added in portfolio.",
    icon: {
      src: "../assets/images/group.svg",
    },
  },
  {
    bgColor: "#9bdcba",
    image: {
      src: "../assets/images/setting-term&condition.png",
      alt: "setting-image",
    },
    barGraph: {
      src: "../assets/images/portfolio-bar-graph.png",
      alt: "portfolio-bar-graph",
    },
    heading: "Setting",
    text1:
      "General Setting Area Screen with information about app/User/Policy.",
    text2: null,

    icon: {
      src: "../assets/images/group.svg",
    },
  },
];

const PortfolioComponent = () => {
  return (
    <div className="portfolio-setting-container">
      <div className="fix-container ">
        <div className="portfolio-section">
          {data.map((item, index) => (
            <div
              className="portfolio-container"
              key={index}
              style={{ backgroundColor: item.bgColor }}
            >
              <div className="bargraph">
                <img
                  src={item.barGraph.src}
                  alt="bargraph"
                  height={22}
                  width={22}
                />
              </div>
              <div className="setting-heading">
                <h4>{item.heading}</h4>
              </div>
              <div className="portfolio-text">
                <p>{item.text1}</p>
                <p>{item.text2}</p>
              </div>

              <div className="portfolio-icon">
                <img src={item.icon.src} alt="" />
              </div>
              <img
                src={item.image.src}
                alt={item.image.alt}
                width={532}
                height={635}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioComponent;
