import { React, useRef } from "react";
import "./Testimonial.css";
import SplideEffect from "../SplideEffect";
import { Star } from "../Icons";

const testimonialsData = {
  Data: [
    // {
    //   name: "Malav r shelat ",
    //   content:
    //     "I have been using this app from beta version to its recent release. It has been giving me great edge over my investments and I am in total control of my money and decisions. The concept of data driven investing is thoughtfully utilised and gives investors what is needed. Should definitely go for this app regardless if you are a beginner or expert. This is for everyone.",
    // },
    {
      name: "Dhruv Davda",
      content:
        "  Top-notch investment guidance! Abillion has been instrumental in helping me choose the right stocks to invest in. Its user-friendly interface and accurate recommendations make investing a breeze. Highly recommended!",
    },
    {
      name: "Navin Navlani ",
      content:
        "I am finding this app very useful for my investments. Very interesting to see stock performance.",
    },
    {
      name: "Rahul Patel",
      content:
        "Awesome application! This the app I was looking for, now I can have my portfolio at one place with all the triggers! WELL DONE!",
    },
    {
      name: "Ravi Diwaker",
      content:
        "I’m using it since release and gaining profit more than 10% as they are suggesting. This app can be very helpful for working professionals like me who are into investing in stocks. You just need to see high probability stocks to invest",
    },
    {
      name: "Anand Shah",
      content:
        "This app suggests stocks that are really great and hit targets on time. I've been watching stocks for the past 10 days, and the app's suggested stock performance is the most accurate it's ever been.",
    },
  ],
  nextButtonSvg: "../assets/images/next-btn.svg",
  prevButtonSvg: "../assets/images/prev-btn.svg",
};

function Testimonial({ testimonialHeading, testimonialHeadingText }) {
  const { nextButtonSvg, prevButtonSvg } = testimonialsData;
  const splideRef = useRef(null);
  const handlePrev = () => {
    if (splideRef.current) {
      splideRef.current.splide.go("-1");
    }
  };

  const handleNext = () => {
    if (splideRef.current) {
      splideRef.current.splide.go("+1");
    }
  };

  return (
    <>
      <div className="testimonial-section" id="testimonial">
        <div className="fix-container">
          <div className="testimonial-container">
            <div className="testimonial-content">
              <div className="testimonial-heading">
                <h4>{testimonialHeading}</h4>
              </div>
              <div className="testimonial-heading-text">
                <p>{testimonialHeadingText}</p>
              </div>
            </div>
            <div className="testimonial-slide">
              <SplideEffect type="loop" splideRef={splideRef}>
                {testimonialsData.Data.map((testimonial, index) => (
                  <div className="testimonial-item" key={index}>
                    <div className="testimonial-icon">
                      <h4>{testimonial.name}</h4>
                    </div>
                    <div className="rating">
                      {[0, 1, 2, 3, 4].map((i) => (
                        <div key={i}>{Star}</div>
                      ))}
                    </div>

                    <div className="testimonial-text">
                      <p>{testimonial.content}</p>
                    </div>
                  </div>
                ))}
              </SplideEffect>
              <div className="testimonial-control">
                <div className="testimonial-control-btn">
                  <img
                    src={prevButtonSvg}
                    alt="btn-prev"
                    onClick={handlePrev}
                    width={50}
                    style={{ cursor: "pointer" }}
                    height={50}
                  />
                  <img
                    src={nextButtonSvg}
                    alt="btn-next"
                    onClick={handleNext}
                    style={{ cursor: "pointer" }}
                    width={50}
                    height={50}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
