import React, { useState } from "react";
import "./Investment.css";
export default function Investment({ data, index }) {
  const [active, setActive] = useState(data.sections[0].name);

  const handleActive = (name, image, altText) => {
    setActive(name);
  };

  return (
    <>
      <div className="investment-container">
        <div className="fix-container">
          <div className="investment-scn">
            <div className="investments">
              <div className="investment-image">
                <div className="image">
                  <img
                    src={
                      data.sections.find((section) => section.name === active)
                        ?.image
                    }
                    alt={
                      data.sections.find((section) => section.name === active)
                        ?.altText
                    }
                    width={517}
                    height={635}
                  />
                </div>
              </div>

              <div className="investment-content">
                <div className="bar-graph">
                  <img
                    src={data.barGraphIcon}
                    alt="bar-graph-icon"
                    width={58}
                    height={58}
                  />
                </div>

                <div className="investment-title">
                  <h4>{data.title}</h4>
                </div>
                <div className="investment-text">
                  {data.sections.map((section, index) => (
                    <div
                      key={index}
                      className={
                        active === section.name
                          ? "investment-gray-text-active p"
                          : "investment-gray-text p"
                      }
                      onClick={() =>
                        handleActive(
                          section.name,
                          section.image,
                          section.altText
                        )
                      }
                    >
                      <p>{section.content}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
