import React from "react";
import "./Feature.css";

const features = [
  {
    id: 1,
    iconSrc: "../assets/images/icon.svg",
    alt: "sector icon",
    text: "Sector Trend",
  },
  {
    id: 2,
    iconSrc: "../assets/images/tranding-activity-icon.svg",
    alt: "feature-icon",
    text: "High Probability Stocks",
  },
  {
    id: 3,
    iconSrc: "../assets/images/data-icon.svg",
    alt: "feature-icon",
    text: "FII/DII Data",
  },
  {
    id: 4,
    iconSrc: "../assets/images/shorttrade-icon.svg",
    alt: "feature-icon",
    text: "Short Trade",
  },
  {
    id: 5,
    iconSrc: "../assets/images/analysis-icon.svg",
    alt: "feature-icon",
    text: "Analyse Your Portfolio",
  },
  {
    id: 6,
    iconSrc: "../assets/images/alert-icon.svg",
    alt: "feature-icon",
    text: "Notifications & Alerts",
  },
];

const Feature = ({ featureRef }) => {
  return (
    <>
      <div className="feature-container" id="features" ref={featureRef}>
        <div className="fix-container">
          <div className="feature-top">
            <div className="feature-heading">
              <h4>Our Features</h4>
            </div>
            <div className="feature-content">
              <p>
                The ABillion Trade online trading platform provides a wide range
                of useful features that you can try out and utilize.
              </p>
            </div>
          </div>
          <div className="feature-bottom">
            <div className="feature-list">
              {features.map((feature) => (
                <div className="feature-item" key={feature.id}>
                  <div className="feature-icon">
                    <img
                      src={feature.iconSrc}
                      alt={feature.alt}
                      width={24}
                      height={24}
                    />
                  </div>
                  <div className="feature-text">
                    <p>{feature.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feature;
