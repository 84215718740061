import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import Mobilemenu from "./Mobilemenu";

function Navbar({ scrollToSection }) {
  const [show, setShow] = useState(false);

  const navLinks = [
    { id: "home", text: "Home", path: "/" },
    { id: "features", text: "Features", path: "/" },
    { id: "testimonial", text: "Testimonial", path: "/" },
    { id: "contact", text: "Contact us", path: "/contact" },
  ];
  const storeLinks = [
    {
      store: "App Store",
      href: "http://apps.apple.com/us/app/abillion-trade-analytics/id6464286930",
      imgSrc: "../assets/images/appstore-icon.svg",
      alt: "App Store Icon",
      width: 122,
      height: 44,
    },
    {
      store: "Google Play",
      href: "http://play.google.com/store/apps/details?id=com.app.abillion",
      imgSrc: "../assets/images/playstore-icon.svg",
      alt: "Google Play Icon",
      width: 122,
      height: 40,
    },
  ];

  const menu = [
    {
      store: "App Store",
      href: "http://apps.apple.com/us/app/abillion-trade-analytics/id6464286930",
      imgSrc: "../assets/images/appstore-menu.png",
      alt: "App Store Icon",
    },
    {
      store: "Google Play",
      href: "http://play.google.com/store/apps/details?id=com.app.abillion",
      imgSrc: "../assets/images/playstore-menu.png",
      alt: "play store icon",
    },
  ];
  const toggleMenu = () => {
    setShow(!show);
  };
  const handleMenuLinkClick = (linkId) => {
    scrollToSection(linkId);
    setShow(false);
  };
  const closeMenu = () => {
    setShow(false);
  };

  const hamburgerMenu = "../assets/images/menu.svg";
  const hamburgerClose = "../assets/images/x.svg";
  const brandLogoSrc = "../assets/images/brand-logo.svg";

  return (
    <header>
      <div className="header">
        <div className="fix-container">
          <nav className="navbar">
            <a href="/">
              <img src={brandLogoSrc} alt="brand logo" width={80} height={52} />
            </a>

            <ul className="navlist">
              {navLinks.map((link) => (
                <li
                  key={link.id}
                  className="navbar-link"
                  onClick={() => scrollToSection(link.id)}
                >
                  <Link className="link" to={link.path} duration={500}>
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="new-app">
              {storeLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={link.imgSrc}
                    alt={link.alt}
                    width={storeLinks.width}
                    height={storeLinks.height}
                  />
                </a>
              ))}
            </div>

            <div className="menu" onClick={toggleMenu}>
              {show ? (
                <img
                  src={hamburgerClose}
                  alt="close icon"
                  width={35}
                  height={35}
                />
              ) : (
                <img
                  src={hamburgerMenu}
                  alt="hamburger icon"
                  width={35}
                  height={35}
                />
              )}
            </div>
          </nav>
        </div>
        <Mobilemenu
          navLinks={navLinks}
          handleMenuLinkClick={handleMenuLinkClick}
          show={show}
          closeMenu={closeMenu}
        />
      </div>
    </header>
  );
}

export default Navbar;
