import React, { useState, useEffect } from "react";
import "./Footer.css";

const footerData = {
  links: [
    {
      id: 1,
      text: "Terms of service",
      path: "/terms-and-condition.html",
    },
    {
      id: 2,
      text: "Privacy policy",
      path: "/privacy-policy.html",
    },
  ],

  socialIcons: [
    {
      id: 1,
      src: "youtube.svg",
      alt: "YouTube",
      url: "https://www.youtube.com/@abillionTA",
    },
    {
      id: 2,
      src: "twitter.svg",
      alt: "Twitter",
      url: "https://twitter.com/AbillionTA",
    },
    {
      id: 3,
      src: "linkedin.svg",
      alt: "LinkedIn",
      url: "https://www.linkedin.com/showcase/abillion-trade-analytics",
    },
    {
      id: 4,
      src: "telegram.svg",
      alt: "Telegram",
      url: "https://web.telegram.org/a/#-1001932861999",
    },
  ],
};
function Footer() {
  const [isIOS, setIsIOS] = useState(false);
  const imagePath = "/assets/images/";
  const handle = () => {
    const iOSAppURLScheme = "abillion-app://";
    const androidAppURLScheme = "abillion-app://";
    const iOSAppStoreURL =
      "http://apps.apple.com/us/app/abillion-trade-analytics/id6464286930";
    const androidPlayStoreURL =
      "http://play.google.com/store/apps/details?id=com.app.abillion";

    let appURLScheme, appStoreURL;

    if (/iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)) {
      appURLScheme = iOSAppURLScheme;
      appStoreURL = iOSAppStoreURL;
    } else if (/Android/i.test(navigator.userAgent)) {
      appURLScheme = androidAppURLScheme;
      appStoreURL = androidPlayStoreURL;
    } else {
      window.location.href = "https://abillionanalytics.com/";
      return;
    }

    window.location.href = appURLScheme;

    setTimeout(() => {
      window.location.href = appStoreURL;
    }, 1000);
  };

  useEffect(() => {
    setIsIOS(
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        !/Android/i.test(navigator.userAgent)
    );
  }, []);

  return (
    <footer>
      <div className="footer">
        <div className="fix-container">
          <div className="footer-scn">
            <div className="footer-bottom">
              <ul className="ftr-botton-list">
                {footerData.links.map((link) => (
                  <li key={link.id} className="ftr-botton-link">
                    <a href={link.path} target="_blank" rel="noreferrer">
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="socil-icon">
              {footerData.socialIcons.map((icon) => (
                <span key={icon.id} className="icon">
                  <a href={icon.url} target="_blank" rel="noreferrer">
                    <img
                      src={imagePath + icon.src}
                      alt={icon.alt}
                      width={16}
                      height={16}
                    />
                  </a>
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="copyright-content">
          <p>
            Copyright © all rights reserved by Abstract Trade Analytics PVT Ltd.
          </p>
        </div>
      </div>
      <div
        className={
          isIOS ? "download-app-container-iphone" : "download-app-container"
        }
      >
        <img src="../assets/images/fixedlog.svg" alt="" className="fixedlogo" />
        <div className="app-content">
          <h4>Get App Now!</h4>
        </div>
        <span onClick={handle} className="download-fixed-btn">
          <img
            src="../assets/images/downloadnowbtn.svg"
            alt="downloadbutton"
            width={136}
            height={40}
          />
        </span>
      </div>
    </footer>
  );
}

export default Footer;
