import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useEffect, useRef } from "react";

const SplideEffect = ({
  children,
  type,
  perPage,
  gap,
  breakpoints,
  trimSpace,
  arrows,
  focus,
  autoWidth,
  pagination,
  splideRef,
  perMove,
}) => {
  const splideInstance = useRef(null);

  useEffect(() => {
    if (splideRef) {
      splideRef.current = splideInstance.current;
    }
  }, [splideRef]);

  return (
    <Splide
      options={{
        type: type || "slide",
        perMove: perMove || 1,
        trimSpace: trimSpace || false,
        perPage: perPage || 2,
        arrows: arrows || false,
        focus: focus || "start",
        gap: gap || "1rem",
        updateOnMove: true,
        autoWidth: autoWidth || true,
        pagination: pagination || false,
        // mediaQuery: "min",
        // breakpoints: breakpoints || {
        //   1024: {
        //     destroy: true,
        //   },
        // },
      }}
      ref={splideInstance}
    >
      {React.Children.map(children, (child, index) => (
        <SplideSlide key={index}>{child}</SplideSlide>
      ))}
    </Splide>
  );
};

export default SplideEffect;
